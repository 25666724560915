.new-recall-dashboard-flex {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  gap: 42px;
  width: 100%;
  background-color: #f4f4f4;
  min-height: 100vh;
}
.dashboard-sidebar-navigation > p {
  font-family: "Inter", sans-serif;
  color: rgba(68, 73, 84, 0.64);

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
  margin-left: 30px;
  margin-top: 10px;
}
.dashboard-sidebar-navigation-item {
  cursor: pointer;
  border-radius: 12px;
  padding: 9px;
  padding-left: 12px;
}
.dashboard-sidebar-navigation-item:hover {
  background-color: #edeff1;
}
.dashboard-sidebar-navigation-item-active {
  background-color: #edeff1;
}
.dashboard-sidebar-navigation-item-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.dashboard-sidebar-navigation-item-flex-left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.dashboard-sidebar-navigation-item-img {
  display: flex;
}
.dashboard-sidebar-navigation-item-img img,
.dashboard-sidebar-navigation-item-flex-right img {
  display: flex;
}
.dashboard-sidebar-navigation > p {
  margin-bottom: 10px;
}
.dashboard-sidebar-navigation-item-text {
  color: #50545e;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
}
.dashboard-sidebar-navigation-item {
  margin-bottom: 9px;
}
.dashboard-sidebar-navigation-item-img > img {
  width: 20px;
}
.dashboard-sidebar-bottom button {
  width: 100%;
  border-radius: 11px;
  background: linear-gradient(180deg, #89cff0 0%, #62bee9 100%);
  padding: 10px 0;
  color: #fff;
  font-family: "Inter", sans-serif;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
  letter-spacing: -0.7px;
}
.new-dashboard-header {
  color: #444954;
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-style: normal;
  margin-top: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.845px;
}
.recall-dashboard-container {
  width: 95%;
  margin-top: 20px;
  height: 85%;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.recall-dashboard-container-top {
  height: 38%;
  display: flex;
  gap: 15px;
}
.recall-dashboard-container-top-right {
  display: flex;
  gap: 15px;
}
.recall-dashboard-container-top-right > div {
  width: 50%;
}
.recall-dashboard-container-top > div {
  width: 50%;
}
.recall-dashboard-container-bottom {
  height: 62%;
  display: flex;
  gap: 15px;
}

.recall-dashboard-container-bottom > div {
  width: 50%;
}
.recall-updated-dashboard {
  height: 100%;
}
.recall-updated-dashboard-elements {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.new-dashboard-white-card {
  background-color: #fff;
}
.new-dashboard-blue-card {
  background: linear-gradient(152deg, #83cdef 17.43%, #6ec3eb 93.86%);
}
.new-dashboard-card {
  overflow: hidden;
  border-radius: 10px;
}
.recall-dashboard-container-top-left-card {
  padding: 22px 26px;
}
.new-dashboard-card {
  padding: 20px 24px;
}
.recall-dashboard-container-bottom-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.new-dashboard-card-header {
  display: flex;
  align-items: center;
  color: #595959;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.438px;
  gap: 8px;
}
.new-dashboard-card-header-rect {
  width: 16px;
  height: 30px;
  border-radius: 4.364px;
}
.new-dashboard-social-share-flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.new-dashboard-social-share {
  display: flex;
  align-items: center;
  gap: 15px;
}
.new-dashboard-social-share img {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.new-dashboard-social-share img:hover {
  transform: translateY(-2px);
}

.energy-saved-top-stats {
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  align-items: center;
  /* gap: 34px; */
  width: 80%;

  justify-content: space-between;
}
.energy-saved-top-stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.energy-saved-top-stats-item img {
  margin-bottom: 8px;
  width: 70px;
}
.energy-saved-top-stats-item-value {
  color: #000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16.316px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.326px;
}
.energy-saved-top-stats-item-name {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  /* font-family: Poppins; */
  font-size: 13.063px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.261px;
}

.wave-card {
  position: relative;
  padding: 0;
}
.wave-card-content {
  padding: 20px 24px;
}
.new-dashboard-react-wave {
  height: 100%;
}
.new-dashboard-react-wave > div {
  height: 100%;
}
.new-dashboard-energies {
  position: absolute;
  z-index: 2;
  bottom: 10%;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 15%;
  justify-content: space-between;
  align-items: center;
}
.new-dashboard-energy {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.new-dashboard-energy p {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
}
.new-dashboard-account-stats h1 {
  color: #484848;
  font-family: "Inter", sans-serif;
  margin-top: 15px;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.569px;
}
.new-dashboard-account-stats-bar {
  border-radius: 23.303px;
  display: flex;
  align-items: center;
  gap: 20px;
  background: #f4f4f4;
  padding: 11px;
  margin-top: 20px;
}
.active-stat-point {
  border-radius: 11.287px;
  background: #fff;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}
.new-dashboard-account-stats-bar-item-name {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #787878;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.3px;
}
.new-dashboard-account-stats-bar-item-value {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #323232;
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 44.015px */
  letter-spacing: -0.8px;
  text-transform: capitalize;
}

.new-dashboard-account-stats-bar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  gap: 10px;
  padding: 19px 0;
}
.new-dashboard-account-stats-bar-item-value-card {
  border-radius: 8px;

  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #313131;
  font-family: "Inter", sans-serif;
  font-size: 22.997px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 25.296px */
  letter-spacing: -0.46px;
  text-transform: capitalize;
}
.new-dashboard-account-stats-bar-item-value-card img {
  transition: all 0.3s ease-in-out;
}
.new-dashboard-account-stats-bar-item-value-card:hover img {
  transform: rotate(-45deg);
}
.choose-pickup {
  color: #fff;
  height: 65%;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  margin-top: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}
.card-buttton {
  width: 100%;
}
.new-card-history {
  height: 65%;
  position: relative;
  overflow: hidden;
  margin-top: 13px;
}
.card-buttton button {
  width: 100%;
  padding: 7px;
  border: none;
  color: #76c7ed;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  background-color: #fff;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
}
.card-button-blue button {
  color: #fff;
  background-color: #76c7ed;
}
.card-height {
  /* height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.history-layer {
  background: linear-gradient(0deg, #fff 11.11%, rgba(255, 255, 255, 0) 87.04%);
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 10px;
}
.analytics-select select {
  border-radius: 9.543px;
  border: 1.337px solid #ececec;
  padding: 7px 13px;
  color: #7a7f86;
  font-family: "Inter", sans-serif;
  font-size: 14.845px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.297px;
}
.analytics-card-scale {
  transform: scale(1.1);
  position: relative;
top: 30px;
}
.analytical-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  padding-bottom: 0;
}
.analytical-card {
  padding: 0;
}
.history-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.history-item-date {
  display: flex;
  align-items: center;
  gap: 10px;
}
.history-item-date-text {
  color: #8f8f8f;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}
.history-item-points {
  color: #38ca04;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.373px;
}
