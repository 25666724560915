.recall-guide-container {
  /* width: 60%; */
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 6rem;
}
.recall-guide-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recall-guide-header-breadcrumb {
  display: flex;
  align-items: center;
  gap: 4px;
}
.recall-guide-header-text {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: rgba(7, 7, 7, 0.75);
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  /* 155.556% */
  letter-spacing: -0.878px;
}
.recall-guide-header-text img {
  display: flex;
  margin-top: 8px;
  align-items: flex-start;
}

.recall-guide-header-text img {
  width: 30px;
}
.recall-guide-header-left p {
  color: rgba(68, 73, 84, 0.6);
  font-family: "Inter", sans-serif;
  font-size: 21.529px;
  font-style: normal;
  font-weight: 500;
  line-height: 28.706px; /* 133.333% */
  letter-spacing: -0.431px;
  margin-top: 5px;
}
.recall-guide-header-breadcrumb-item {
  color: rgba(68, 73, 84, 0.64);
  font-family: "Inter", sans-serif;
  font-size: 21.529px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.49px; /* 155.556% */
  letter-spacing: -0.431px;
  cursor: pointer;
}
.item-2 {
  color: rgba(7, 7, 7, 0.75);
}
.recall-guides {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.recall-guides > div {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.recall-guide-img img {
  border-radius: 12px;
  border: 1.5px solid #e4e2de;
}
.recall-guides > div:hover {
  transform: translateY(-5px);
}
.recall-guides > div:hover .recall-guide-img img {
  border: 1.5px solid #8e8e8e;
}

.recall-guide-text {
  margin-top: 17px;
}
.recall-guide-text-title {
  color: #3b3b3b;
  font-family: "Inter", sans-serif;
  font-size: 26.423px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.423px; /* 100% */
  letter-spacing: -0.793px;
}
.recall-guide-text-desc {
  color: rgba(104, 104, 104, 0.85);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  opacity: 0.85;

  letter-spacing: -0.528px;
}
.recall-guide-text-read {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgba(104, 104, 104, 0.85);
  font-family: "Inter", sans-serif;
  font-size: 15.413px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.423px; /* 171.429% */
  letter-spacing: -0.462px;
}
.thumbnail-img-text {
  color: rgba(104, 104, 104, 0.85);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.423px; /* 150% */
  letter-spacing: -0.528px;
  margin: 24px 0;
}
.guide-point {
  margin-bottom: 20px;
}
.guide-point-title {
  color: rgba(7, 7, 7, 0.75);
  font-family: "Inter", sans-serif;
  font-size: 43.879px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 57.043px */
  letter-spacing: -0.878px;
}
.guide-point-text {
  color: #72787a;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.423px; /* 132.115% */
  letter-spacing: -0.2px;
}

.intro-subtitle {
  color: rgba(104, 104, 104, 0.85);
  font-family: "Inter", sans-serif;
  font-size: 17.615px;
  margin: 20px 0;
  width: 80%;
  font-style: normal;
  font-weight: 500;
  line-height: 26.423px; /* 150% */
  letter-spacing: -0.528px;
}
.thumbnail-img {
  width: 100%;
}
.thumbnail-img img {
  width: 100%;
}
