
  

  
  .container { /* Adjust based on the navbar height */
    padding: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 2em;
  }
  
  .all-locations {
    display: flex;
    align-items: center;
    margin: 0 0 64px 0;
    padding-bottom: 10px;
  }
  
  .all-locations h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .bin-locations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .bin-location {
    display: flex;
    align-items: center;
  }
  
  .icon-id {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .bin-icon-all{
    width: 12px;
    height: 40px;
    background-color: #89CDED;
    margin-right: 10px;
  }
  .bin-name-all{
     color: #000;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .bin-num{
    color: #89CDED;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 10px;
  }
  .bin-name{
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .binLoc{
    margin-top: 58px;
  }

  .img-binloc {
    width: 100%;
    display: block; /* Ensures no extra space below the image */
  }
  
  
  