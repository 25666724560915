.recall-contact-container-top {
  display: flex;
  gap: 15px;
  width: 100%;
}

.recall-contact-container-top-left {
  width: 74%;
}
.recall-contact-container-top-right {
  width: 26%;
}
.recall-contact-container-top {
  height: 72%;
}
.recall-report-container-top {
  height: 100%;
}
.recall-report-container-top .recall-contact-container-top-right {
  height: 70%;
}
.recall-contact-container-bottom {
  height: 28%;
}
.client-feedbacks {
  margin-top: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}
.client-feedback {
  border-radius: 10px;
  background: #f4f4f4;
  padding: 14px 22px;
}
.client-feedback-text {
  color: #656565;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}
.client-feedback-name {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-top: 8px;
  line-height: normal;
  letter-spacing: -0.4px;
}

.new-dashboard-contact-form {
  width: 100%;
  margin-top: 20px;
}
.new-dashboard-contact-form form {
  width: 100%;
}
.new-dashboard-contact-form-input {
  width: 100%;
  margin-bottom: 15px;
}
.new-dashboard-contact-form-input p {
  margin-bottom: 4px;
  color: #757575;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.new-dashboard-contact-form-input input,
.new-dashboard-contact-form-input textarea {
  width: 100%;
  border-radius: 9px;
  border: 1.5px solid #e6e6e6;
  padding: 9px 23px;
  color: #999;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.54px;
}

.new-dashboard-contact-form-input input::placeholder,
.new-dashboard-contact-form-input textarea::placeholder {
  color: #999;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.54px;
}
.new-dashboard-contact-form-input textarea {
  resize: none;
  max-height: 500px;
  min-height: 160px;
}
.new-dashboard-contact-form button {
  border-radius: 6px;
  background: #85cef0;
  padding: 10px 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  border: none;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.368px;
}
.recall-support-img {
  margin-top: 15px;
  width: 100%;
}
.recall-support-img img {
  width: 100%;
  display: flex;
}
.recall-call-details-item {
  border-radius: 12px;
  background: #edeff1;
  padding: 9px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 15px;

  margin-top: 14px;
}
.recall-call-details-item p {
  color: #444954;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.recall-call-details-item span {
  display: block;
  color: #909090;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
}
.dotted-item {
  border-radius: 12px;
  align-items: flex-start;
  border: 1px dashed #868686;
  background: transparent;
}
.draganddrop {
  border-radius: 9px;
  border: 1.5px solid #e6e6e6;
  background: #f6f6f6;
}
.report-text-area textarea {
  min-height: 130px;
  max-height: 130px;
}
.draganddrop p {
  color: #6f6f6f;
  margin-top: 11px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}
.new-dashboard-contact-form-input span {
  color: #bbb;
  font-family: "Inter", sans-serif;
}
