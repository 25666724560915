.recall-login-modal {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}
.recall-login-modal-left {
  position: relative;
  height: 100%;
  width: 50%;
}
.recall-login-modal-right {
  width: 50%;
  padding: 2rem;
}
.recall-login-modal-left img {
  height: 100%;
  object-fit: cover;
  display: flex;
  width: 100%;
}
.recall-login-modal-left-text {
  position: absolute;
  bottom: 4rem;
  z-index: 3;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 120%; /* 43.2px */
  letter-spacing: -1.08px;
  padding: 0 1.5rem;
  left: 1rem;
}
.recall-login-modal-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5.6rem;
  gap: 1rem;
}

.recall-login-modal-buttons button {
  border-radius: 11.826px;
  background: #89cff0;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.54px;
  border: none;
  padding: 14px;
}
.recall-login-modal-buttons button.secondary {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}
.recall-login-modal-info h1 {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: 120%; /* 33.6px */
  letter-spacing: -0.84px;
  margin-top: 22px;
  margin-bottom: 30px;
}
.recall-login-modal-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
}
.recall-login-modal-list p {
  color: rgba(86, 86, 86, 0.85);
  font-size: 18px;
  font-weight: 500;
}
.recall-login-modal-buttons p {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.54px;
}
.recall-login-modal-buttons p span {
  font-weight: 600;
  color: #89cff0;
  cursor: pointer;
}
.recall-login-modal-left .login-modal-phone {
  display: none;
}
@media (max-width: 1000px) {
  .recall-login-modal {
    flex-direction: column;
  }
  .recall-login-modal-left {
    width: 100%;
  }
  .recall-login-modal-right {
    width: 100%;
    padding: 1.2rem;
  }
  .recall-login-modal-left .login-modal-laptop {
    display: none;
  }
  .recall-login-modal-left .login-modal-phone {
    display: flex;
  }
  .recall-login-modal-left img {
    height: 230px;
  }
  .recall-login-modal-left-text {
    bottom: 1.5rem;
    left: 0rem;
    font-size: 18px;
  }
  .recall-login-modal-info h1 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .recall-login-modal-buttons {
    margin-top: 1.4rem;
  }
  .recall-login-modal-list p {
    font-size: 14px;
  }
  .recall-login-modal-list {
    gap: 10px;
  }
  .recall-login-modal-logo img {
    width: 90px;
  }
  .recall-login-modal-buttons button {
    font-size: 14px;
    padding: 10px;
  }
  .recall-login-modal-buttons p {
    font-size: 13px;
  }
  .recall-login-modal-buttons {
    gap: 0.5rem;
  }
}
.recall-store-otp {
  text-align: center;
}
.recall-store-otp-logo {
  margin-top: 30px;
  margin-bottom: 25px;
}
.recall-store-otp-header h1 {
  color: #444954;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.9px;
}
.recall-store-otp-header p {
  color: #909090;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.48px;
}
.recall-store-otp-input {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}
.recall-store-otp-input > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.recall-store-otp-input input {
  margin: 0 !important;
  width: 65px !important;
  height: 65px !important;
  border-radius: 9.467px !important;
  border: 1px solid #e2e2e2 !important;
  background: #f9f9f9 !important;
  font-size: 24px;
}
.recall-store-otp-button {
  width: 85%;
  margin: 0 auto;
  margin-top: 40px;
}
.recall-store-otp-button button {
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.4px;
  padding: 11px;
  border-radius: 12.595px;
  border: none;
  background: #89cff0;
}
