.dashboard-sidebar {
  background-color: #fcfcfc;

  border-right: 1px solid #e1e1e1;
  padding: 20px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard-sidebar-logo img {
  width: 140px;
}
