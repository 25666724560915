.recall-profile-container-top {
  display: flex;
  gap: 15px;
  width: 100%;
}

.recall-profile-container-top-left {
  width: 30%;
}
.recall-profile-container-top-right {
  width: 70%;
}
.recall-profile-container-top {
  height: 70%;
}

.recall-profile-container-bottom {
  height: 30%;
  display: flex;
  gap: 15px;
}
.recall-profile-container-bottom-left {
  width: 30%;
}
.recall-profile-container-bottom-right {
  width: 70%;
}